<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addAgent') : $t('message.updateAgent') + ": " + agentName }}</span>
                <HelpPopUpV2 help-page-component="AddAgent" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="agentForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.name') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plain]"
                                    :value="Company__name"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__name = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.scac') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Agent__scac"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Agent__scac = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.address1') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Company__address1"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__address1 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.address2') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Company__address2"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__address2 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.address3') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Company__address3"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__address3 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.city') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="Company__city"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__city = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.postcode') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Company__postcode"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__postcode = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.state') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="Company__state"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__state = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.country') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-autocomplete
                                    :rules="[...validationRules.required]"
                                    :items="allCountries"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.name"
                                    item-value="Country.id"
                                    solo
                                    v-model="Company__country_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.website') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.website]"
                                    :value="Company__website"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__website = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.telephone') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.phoneNumber]"
                                    :value="Company__tel"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__tel = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.fax') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.phoneNumber]"
                                    :value="Company__fax"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__fax = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.email') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.email]"
                                    :value="Company__email"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Company__email = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addAgent()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveAgent()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddAgent",
    components: {HelpPopUpV2},
    props: ['agentId','agentName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('agent',{
            Agent__id: 'Agent.id',
            Agent__scac: 'Agent.scac',
            Agent__title: 'Agent.title',
            Company__address1: 'Company.address1',
            Company__address2: 'Company.address2',
            Company__address3: 'Company.address3',
            Company__city: 'Company.city',
            Company__country_id: 'Company.country_id',
            Company__email: 'Company.email',
            Company__fax: 'Company.fax',
            Company__id: 'Company.id',
            Company__name: 'Company.name',
            Company__postcode: 'Company.postcode',
            Company__state: 'Company.state',
            Company__tel: 'Company.tel',
            Company__website: 'Company.website',
        },'statePrefix'),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('agent',{
            createAgent: 'createAgent',
            getAgentToUpdateById: 'getAgentToUpdateById',
            resetAgentToUpdate: 'resetAgentToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateAgent: 'updateAgent'
        }),
        ...mapActions('country',{
            getAllCountries: ' getAllCountries'
        }),
        addAgent () {
            if(this.$refs.agentForm.validate()) {
                this.loading.add = true
                this.createAgent()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.agentAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.agentNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.agentNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.agentNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveAgent () {
            if(this.$refs.agentForm.validate()) {
                this.loading.save = true
                this.updateAgent()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.agentUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.agentNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.agentNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.agentNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getAgentToUpdateById(this.agentId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetAgentToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCountries.length == 0) this.getAllCountries()
    }
}
</script>

<style>

</style>